import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="The '*in mice' bot" />
    <p>
      *<span class="italic">In-mice</span> is a reddit bot that tracks posts in the popular <Link to="https://www.reddit.com/r/science/">r/science</Link> community. 
      When it finds a headline that 
      tries to frame mouse model research breakthroughs as human medical science, 
      it adds the comment ‘…*in mice’ to the post.
    </p>
    <p>
      For example: <Link to="https://www.eurekalert.org/news-releases/937314#.YbRi78UpmkM.reddit">The headline</Link> ‘Nasal 
      Vaccine may Aid Fight Against New Viral Variants’ may lead readers to believe that this vaccine 
      has been tested in humans and will soon be available to the public. In reality, 
      the <Link to="https://www.science.org/doi/10.1126/sciimmunol.abj5129">research paper</Link> is 
      about a set of mouse experiments, and it could be years before this translates to a human vaccine. 
      A more responsible headline would be ‘Nasal Vaccine Generates Robust Antiviral 
      Immune Response in Mouse Experiments’.
    </p>
    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    /> */}
    <h2>Why do we need this?</h2>
    
    <p>
      Molecular, biochemical and occasionally even behavioral research in 🐁 models 
      can be very fruitful. But it’s important to note that many results seen in animal 
      models will take years before they are translated to the clinic. Many don’t make it.
    </p>
    <p>
      When scientists publish their research, most journals insist that the species 
      used to perform research be included in the title. This helps put the research in 
      context. But when a research paper is converted into a headline for a ‘science article’, a
       lot of nuance is left out, to make the reader more likely to click through to the text. Since 
       most don’t read past the headline, especially on Reddit, it’s easy to take away  
       the wrong message. For those who do click through, it’s frustrating to have to scroll 
       through the whole article, only to realize that the research is less significant than they expected. Hence the 🤖.
    </p>
    <p>
      Responsible editors will write a headline like <Link to="https://www.reddit.com/r/science/comments/ren6cc/japanese_scientists_create_vaccine_for_aging_to/">this 
      one</Link>:‘Japanese 
      scientists create vaccine for aging to eliminate aged cells, reversing 
      artery stiffening, frailty, and diabetes in normal and accelerated aging mice’. 
      It’s not very attractive, but it’s accurate, and doesn’t waste the readers’ time. 
      In-mice bot approves of these headlines.
    </p>


    <h2>How was the bot made?</h2>
    <p>
    Using <Link to="https://praw.readthedocs.io/en/stable/">PRAW</Link>, the <Link to="https://www.ncbi.nlm.nih.gov/books/NBK25500/">Pubmed APIs</Link>, and some basic NLP.
    </p>
    <h2>Also check out</h2>
    <p>
      The <a href="https://doi.in-mice.com">DOI check tool</a>. Also built for users of r/science, the tools find the original digital object identifier from Reddit posts, journal articles or science news stories.
    </p>
    <h2>Contact</h2>
    <p>
      I’m always looking to improve the bot, and for 
      new platforms to point out misleading science headlines. 
      To contribute, collaborate, or suggest new places to 
      deploy, DM the <Link to="https://www.reddit.com/user/in-mice_bot">bot</Link> or its <Link to="https://www.reddit.com/user/in-mice">human friend</Link> on Reddit. 
      To give feedback on a specific comment or point out a wrong call, 
      reply to the comment with 'bad bot!'.
    </p>
    {/* <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
      <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
      <Link to="/using-dsg">Go to "Using DSG"</Link>
    </p> */}
  </Layout>
)

export default IndexPage
